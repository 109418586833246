import { type ApiResponse, type ApiResponseInner, type Pagination, type PaginationOptions } from '~/types/global';

import { useCommonStore } from '../common';
import { type GetBoardContentOptions, communityService } from '../community';
import { paymentService } from './service';
import {
  type FreePaymentData,
  type FreePaymentOptions,
  type PrePaymentData,
  type PrePaymentOptions,
  type UserSurveyForm,
} from './type';

interface State {
  // designModuleMainList: { items: DesignModule[] };

  prePaymentData: PrePaymentData;
  freePaymentData: FreePaymentData;
}

export const usePaymentStore = defineStore('payment-store', {
  state: (): State => ({
    prePaymentData: {} as PrePaymentData,
    freePaymentData: {} as FreePaymentData,
  }),
  persist: false,
  actions: {
    async sendPaymentWebhook(paymentWebhook) {
      const res = await paymentService.sendPaymentWebhook(paymentWebhook);
      return res;
    },
    async prePayment(param: PrePaymentOptions) {
      const res = await paymentService.prePayment(param);
      this.prePaymentData = res.data.data.items;
      return res;
    },
    async freePayment(param: FreePaymentOptions) {
      const res = await paymentService.freePayment(param);
      this.freePaymentData = res.data.data.items;
      return res;
    },
    async paymentResultCheck(uid: string) {
      const res = await paymentService.paymentResultCheck(uid);
      return res;
    },
    async paymentAuthCheck(displaySeq: number) {
      const res = await paymentService.paymentAuthCheck(displaySeq);
      return res;
    },
    async paymentOptionCheck(displaySeq: number, optionSeq: number) {
      const res = await paymentService.paymentOptionCheck(displaySeq, optionSeq);
      return res;
    },
    async fetchSurvey(displaySeq: number) {
      const res = await paymentService.fetchSurvey(displaySeq);
      return res.data.data.items;
    },
    async addSurvey(surveySeq: number, surveyResult: UserSurveyForm) {
      const res = await paymentService.addSurvey(surveySeq, surveyResult);
      return res;
    },
    async addSurveyLegacy(displaySeq: string, surveyResult: UserSurveyForm) {
      const res = await paymentService.addSurveyLegacy(displaySeq, surveyResult);
      return res;
    },
    async updateSurvey(surveySeq: number, surveyResult: UserSurveyForm) {
      const res = await paymentService.updateSurvey(surveySeq, surveyResult);
      return res;
    },
    async cancelPortonePayment(couponSeq: number, optionSeq: number) {
      const res = await paymentService.cancelPortonePayment(couponSeq, optionSeq);
      return res;
    },
    async fetchCaptchaImg(displaySeq: string) {
      const res = await paymentService.fetchCaptchaImg(displaySeq);
      const {
        headers: { identifykey },
      } = res;
      return [res?.data, identifykey];
    },
  },
  getters: {},
});
