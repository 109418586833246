import { type ApiResponseInner, type PaginationOptions } from '~/types/global';

import BaseService from '../BaseService';
import { type ClassByCategoryListOptions, type ProductWithPaging } from '../main/type';
import {
  type FreePaymentData,
  type FreePaymentOptions,
  type IMportPaymentResult,
  type PrePaymentData,
  type PrePaymentOptions,
  type UserSurveyDTO,
  type UserSurveyForm,
} from './type';

class PaymentService extends BaseService {
  constructor() {
    super('');
  }

  fetchSurvey(displaySeq: number) {
    return this.get<ApiResponseInner<UserSurveyDTO>>(`/user/survey/display/${displaySeq}`, {});
  }

  addSurveyLegacy(displaySeq: string, surveyResult: UserSurveyForm) {
    return this.post<ApiResponseInner<any>>(`/user/class/survey/${displaySeq}`, surveyResult);
  }

  addSurvey(surveySeq: number, surveyResult: UserSurveyForm) {
    return this.post<ApiResponseInner<any>>(`/user/survey/${surveySeq}/answer`, surveyResult);
  }

  updateSurvey(surveySeq: number, surveyResult: UserSurveyForm) {
    return this.put<ApiResponseInner<any>>(`/user/survey/${surveySeq}/answer`, surveyResult);
  }

  sendPaymentWebhook(paymentWebhook: { imp_uid: string; merchant_uid: string }) {
    return this.post<ApiResponseInner<any>>(`/user/payment/portone/webhook`, {
      imp_uid: paymentWebhook.imp_uid,
      merchant_uid: paymentWebhook.merchant_uid,
    });
  }

  fetchClassByCategoryList(params: ClassByCategoryListOptions) {
    return this.get<ProductWithPaging>(`/class/list/${params.cateSeq}`, { params });
  }

  deleteFavorite(displaySeq: number) {
    return this.delete<ApiResponseInner<any>>(`/class/favorite/${displaySeq}`, {});
  }

  cancelPortonePayment(displaySeq: number, optionSeq: number) {
    if (optionSeq == null) {
      optionSeq = 0;
    }
    return this.delete<ApiResponseInner<any>>(`/user/payment/portone/cancel/${displaySeq}/${optionSeq}`, {});
  }

  prePayment(param: PrePaymentOptions) {
    return this.post<ApiResponseInner<PrePaymentData>>(`/user/payment/pre`, param);
  }

  freePayment(param: FreePaymentOptions) {
    return this.post<ApiResponseInner<FreePaymentData>>(`/user/payment/free`, param);
  }

  paymentAuthCheck(displaySeq: number) {
    return this.get<ApiResponseInner<PrePaymentData>>(`/user/payment/check/${displaySeq}`, {});
  }

  paymentResultCheck(uid: string) {
    return this.get<ApiResponseInner<PrePaymentData>>(`/user/mypage/payment/detail/${uid}`, {});
  }

  paymentOptionCheck(displaySeq: number, optionSeq: number) {
    return this.get<ApiResponseInner<PrePaymentData>>(`/user/payment/check/option/${displaySeq}/${optionSeq}`, {});
  }

  fetchCaptchaImg(displaySeq: string) {
    return this.get<ApiResponseInner<UserSurveyDTO>>(`/user/payment/captcha/displaySeq/${displaySeq}`, {
      responseType: 'blob',
    });
  }
}

export const paymentService = new PaymentService();
